//main
$base-font: "Lato", sans-serif;
$font-desktop: 16px;
$font-mobile: 14px;
$base-color: #004854;
$base-white: #FFFFFF;

//colors
$light-grey: #f2f5f5;
$color-blue: #76c3d3;
$table-header: #d8e6e6;
$table-border: #f8fbfb;
$input-border: #e8ebeb;
$panel-hover: #e5f0f2;
