header,
#CMSHeaderDiv,
.scroll-element {
    display: none !important;
}

h1 {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 30px;
}

table {
    font-size: 8px;

    tr {
        td {
            border-top: 1px solid #76c3d3;
        }

        th,
        td {
            padding: 3px 4px !important;
            border-right-color: #76c3d3 !important;
            white-space: normal !important;

            &:first-of-type {
                padding: 3px 4px 3px 15px !important;
            }

            &:last-of-type {
                display: none;
            }

            &[data-tag="ProductType"] {
                width: 40px !important;
            }

            &[data-tag="ProductParameterProductPriceNote"],
            &[data-tag="ProductParameterProductNoteUsed"],
            &[data-tag="ProductPresentOnAgrotec"],
            &[data-tag="ProductParameterProductSPZ"] {
                width: 50px !important;
            }

            &[data-tag="ProductBrandName"] {
                width: 70px !important;
            }

            &[data-tag="ProductParameterProductExtraOptions"] {
                width: 80px !important;
            }
        }
    }
}

#productTable tbody tr td:first-of-type:before {
    left: 0;
    font-weight: 700;
}
